<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title align-items-center">
        <h3 class="card-label font-weight-bolder mb-0">{{ $t("Employees Documents") }}</h3>
      </div>
      <div class="card-toolbar">
        <euro-checkbox v-model="expiring" :text="$t('Expiring')" size="lg" is-switch class="mr-6 mt-5"
          @input="$refs.table.refresh()"></euro-checkbox>
        <euro-select v-model="userSelected" class-name="mb-0 min-w-250px mr-4" placeholder="Select employee"
          :options="users" @input="$refs.table.refresh()"></euro-select>
        <euro-select v-model="documentType" class-name="mb-0 min-w-250px" placeholder="Select type" :options="documentOptions"
          @input="$refs.table.refresh()"></euro-select>
      </div>
    </div>
    <div class="card-body detail">
      <b-container class="px-0">
        <b-row>
          <b-col>
            <datatable ref="table" :table-props="tableProps" :total="total" :options.sync="tableOptions"
              :per-page-options="perPageOptions">
              <template #[`cell.persona_name`]="{ item }">
                <button class="label label-inline label-light-primary" @click="edit(item)"> <i class="fa fa-user-edit mr-2"></i>{{ item.persona_name }}</button>
              </template>
              <template #[`cell.document_type`]="{ item }">
                <span :id="'a1-' + item.id"
                  :class="`font-weight-bold label label-inline label-light-${docTypes[item.document_type].labelColor}`">
                  {{ docTypes[item.document_type].typeName }}
                </span>
              </template>

              <template #[`cell.name`]="{ item }">
                <div class="font-weight-bolder h-100">
                  {{ docTypes[item.document_type].name(item) }}
                </div>
                <div v-if="item.document_type == 'PayCheck'" class="text-muted text-size-sm">
                  {{ $t("Period") }}: {{ DateService.format(item.date, "YYYY-MM") }}
                </div>
              </template>

              <template #[`cell.status`]="{ item }">
                <div v-if="item.document_type != 'PayCheck' && item.document_type != 'FiscalID'">
                  <!-- Se uno dei due a1 è valido allora lo stato è valido -->
                  <div>
                    <div v-if="isExpired(item) === 'Valid'">
                      <span class="label label-success label-dot mr-2"></span>
                      <span class="font-weight-bold text-success">{{ $t("Valid") }}</span>
                    </div>
                    <div v-else-if="isExpired(item) === 'Expiring'">
                      <span class="label label-warning label-dot mr-2"></span>
                      <span class="font-weight-bold text-warning">{{ $t('Expiring') }}</span>
                    </div>
                    <div v-else>
                      <span class="label label-danger label-dot mr-2"></span>
                      <span class="font-weight-bold text-danger">{{ $t("Expired") }} </span>
                    </div>
                  </div>
                </div>
              </template>

              <template #[`cell.valid_to`]="{ item }">
                <span>
                  {{ item.valid_to ? DateService.format(item.valid_to, dateFormat) : "" }}
                </span>
              </template>

              <template #[`cell.actions`]="{ item }">
                <div class="d-flex">
                  <b-button v-b-tooltip.hover
                            :title="item.attachment ? titleDownloadButton(item.is_authorized) : titleNotPermissionAndNotContentButton(item.is_authorized)"
                            class="btn btn-icon btn-light btn-sm"
                            :class="{ 'btn-hover-primary': item.is_authorized && item.attachment }"
                            :disabled="!item.is_authorized || !item.attachment || isAfterSale"
                            @click="item.is_authorized && item.attachment ? download(item) : null">
                    <span class="svg-icon svg-icon-md"
                          :class="{ 'svg-icon-primary': item.is_authorized && item.attachment }">
                      <inline-svg src="/media/svg/icons/General/Clip.svg" />
                    </span>
                  </b-button>
                  <b-button v-b-tooltip.hover :title="$t('View Details')"
                            class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                            @click="edit(item)">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg src="/media/svg/icons/General/User.svg" />
                    </span>
                  </b-button>
                </div>
              </template>
            </datatable>
            <validation-observer ref="validationObserver" v-slot="{ handleSubmit }">
              <b-modal ref="editModal" :title="$t('Edit Form')" size="xl" hide-footer @hidden="onEditModalHidden">
                <template #modal-header>
                  <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
                    <h3>Edit {{ docTypes[editModal.form.document_type].typeName }}</h3>
                    <div class="d-flex align-items-center">
                      <b-button variant="success" class="mr-2"
                        :class="{ 'spinner spinner-light spinner-right': editModal.isSaving }"
                        @click="handleSubmit(updateDocument)">
                        {{ $t("Save Changes") }}
                      </b-button>
                      <b-button @click="closeEditModal">
                        {{ $t("Cancel") }}
                      </b-button>
                    </div>
                  </div>
                </template>
                <component :is="docTypes[editModal.form.document_type].form.component"
                  v-if="editModal.form && docTypes[editModal.form.document_type].isEditable"
                  v-bind="docTypes[editModal.form.document_type].form.props"
                  v-on="docTypes[editModal.form.document_type].form.events"></component>
                <p v-else>{{ $t("Edit action not available for this document.") }}</p>
              </b-modal>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//Services
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import SecondmentService from "@/core/services/secondment/secondment.service";
import PhysicalPersonFiscalService from "@/core/services/physical-person/physical-person-fiscal.service";
import PhysicalPersonEducationService from "@/core/services/physical-person/physical-person-education.service";
import PhysicalPersonLanguageService from "@/core/services/physical-person/physical-person-language.service";
import PhysicalPersonPassportService from "@/core/services/physical-person/physical-person-passport.service";
import PhysicalPersonIdentityService from "@/core/services/physical-person/physical-person-identitydocument.service";
import PhysicalPersonEuropeanHealthInsuranceService from "@/core/services/physical-person/physical-person-europeanhealthinsurance.service";
import PhysicalPersonA1ModuleService from "@/core/services/physical-person/physical-person-a1-module.service";
import PhysicalPersonEuropeandrivinglicenseService from "@/core/services/physical-person/physical-person-europeandrivinglicense.service";
import PhysicalPersonResidencePermitService from "@/core/services/physical-person/physical-person-residence-permit.service";
import PhysicalPersonSuitabilityWorkService from "@/core/services/physical-person/physical-person-suitability-work.service.js";
import PhysicalPersonPaycheckService from "@/core/services/physical-person/physical-person-employee-paycheck.service"

// Forms
// import PhysicalPersonPassportForm from "@/view/components/forms/physical-person/PhysicalPersonPassportForm.vue";
// import PhysicalPersonFiscalForm from "@/view/components/forms/physical-person/PhysicalPersonFiscalForm.vue";
// import PhysicalPersonLanguageForm from "@/view/components/forms/physical-person/PhysicalPersonLanguageForm.vue";
// import PhysicalPersonEducationForm from "@/view/components/forms/physical-person/PhysicalPersonEducationForm.vue";
// import PhysicalPersonIdentityForm from "@/view/components/forms/physical-person/PhysicalPersonIdentityForm.vue";
// import PhysicalPersonEuHealthInsuranceForm from "@/view/components/forms/physical-person/PhysicalPersonEuHealthInsuranceForm.vue";
// import PhysicalPersonA1ModuleForm from "@/view/components/forms/physical-person/PhysicalPersonA1ModuleForm.vue";
// import PhysicalPersonEuDrivingLicenseForm from "@/view/components/forms/physical-person/PhysicalPersonEuDrivingLicenseForm.vue";
// import PhysicalPersonPaycheckForm from "@/view/components/forms/physical-person/PhysicalPersonPaycheckForm.vue";

// Other
import { backendErrorSwal } from "@/core/helpers/swal"
import { mapGetters, mapState } from "vuex";
// import moment from "moment";
import DateService from "@/core/services/date.service";
import icons from "@/core/config/icons";
import { perPageOptions } from "@/core/config/datatable.config.js"
import { successToast } from '@/core/helpers';
import PhysicalPersonVisaService from "@/core/services/physical-person/physical-person-visa-service";
import EmployeeContractService from "@/core/services/employee/employee-contract.service";
import PhysicalPersonEuropeanDrivingLicenseService
  from "@/core/services/physical-person/physical-person-europeandrivinglicense.service";
import CompanyVatService from "@/core/services/company/company-vat.service";
import CompanyRegistrationCertificateService from "@/core/services/company/company-registration-certificate.service";
import CompanyInsuranceCertificateService from "@/core/services/company/company-insurancecertificate.service";
import CompanySocialSecurityService from "@/core/services/company/company-socialsecurity.service";
import EmployeeService from "@/core/services/employee/employee.service";


export default {
  props: {
    secondment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      DateService,
      documentType: null,
      editingPerson: null,
      languageLevels: [],
      educationKinds: [],
      administrativeStructures: [],
      firstLoad: true,
      // items: [],
      userSelected: null,
      users: [],
      fields: [
        {
          label: this.$t("Employee"),
          key: "persona_name",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Type"),
          key: "document_type",
          sortable: true,
          class: "align-middle",
        },
        { label: this.$t("Name"), key: "name", sortable: false, class: "align-middle" },
        { label: this.$t("Status"), key: "status", sortable: false, class: "align-middle" },
        {
          label: this.$t("Expiring in"),
          key: "expiring_in_days",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Valid To"),
          key: "date_end",
          sortable: true,
          class: "align-middle",
        },
        {
          label: this.$t("Actions"),
          key: "actions",
          class: "align-end col-actions",
        },
      ],
      isLoading: true,
      perPageOptions: perPageOptions,
      tableOptions: {
        "sort-by": "persona_name",
        "sort-desc": false,
        "per-page": 10,
        "no-local-sorting": false,
      },
      editModal: {
        form: null,
        isSaving: false,
        validationErrors: null,
      },
      postingCertificateMap: {},
      documentOptions: [],
      total: 0,
      searchFilter: "",
      expiring: false,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    ...mapState("constants", ["countries", "languages"]),
    ...mapGetters("user", ["dateFormat", "isAfterSale"]),
    
    tableProps() {
      return {
        // busy: this.isLoading,
        fields: this.fields,
        items: this.itemProvider,
        filter: this.searchFilter,
      };
    },
    
    ...mapGetters("user", ["dateFormat"]),
    

    docTypes() {
      return {
        FiscalID: {
          typeName: "Fiscal ID",
          labelColor: "danger",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "physical-fiscal-create-modal",
          downLoadFunc: (item) => PhysicalPersonFiscalService.getOne(item),
          delFunc: (item) => PhysicalPersonFiscalService.delete(item.id),
        },
        Passport: {
          typeName: "Passport",
          labelColor: "info",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-passport-modal",
          downLoadFunc: (item) => PhysicalPersonPassportService.getOne(item.id),
          delFunc: (item) => PhysicalPersonPassportService.delete(item),
        },
        Visa: {
          typeName: "Visa",
          labelColor: "warning",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-visa-modal",
          downLoadFunc: (item) => PhysicalPersonVisaService.getOne(item.id),
          delFunc: (item) => PhysicalPersonVisaService.delete(item),
        },
        EmployeeContract: {
          typeName: "Contract",
          labelColor: "info",
          name: (item) =>
            !this.buttonDisabled(item.is_authorized) ? item.data?.national_contract : "xxxxxx",
          isEditable: true,
          isDeletable: true,
          downLoadFunc: (item) => EmployeeContractService.getOne(item.id), //window.open(item.attachment, "_blank"),
          delFunc: (item) => EmployeeContractService.delete(item.id),
        },
        IdentityDocument: {
          typeName: "ID Document",
          labelColor: "info",
          name: (item) =>
            item.country + " " + (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-identify-modal",
          downLoadFunc: (item) => PhysicalPersonIdentityService.getOne(item),
          delFunc: (item) => PhysicalPersonIdentityService.delete(item),
        },
        EducationLevel: {
          typeName: "Education Level",
          labelColor: "success",
          name: (item) => item.label,
          isEditable: true,
          isDeletable: true,
          modal: "create-education-modal",
          downLoadFunc: (item) => PhysicalPersonEducationService.getOne(item),
          delFunc: (item) => PhysicalPersonEducationService.delete(item),
        },
        KnownLanguage: {
          typeName: "Language",
          labelColor: "warning",
          name: (item) => item.data.level + " " + item.label,
          isEditable: true,
          isDeletable: true,
          modal: "create-language-modal",
          downLoadFunc: (item) => PhysicalPersonLanguageService.getOne(item),
          delFunc: (item) => PhysicalPersonLanguageService.delete(item.id),
        },
        EuropeanHealthInsuranceCard: {
          typeName: "EU health insurance",
          labelColor: "warning",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-eu-health-modal",
          downLoadFunc: (item) =>
            PhysicalPersonEuropeanHealthInsuranceService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonEuropeanHealthInsuranceService.delete(item.id),
        },
        EuropeanDrivingLicense: {
          typeName: "EU driving license",
          labelColor: "success",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-eu-driving-modal",
          downLoadFunc: (item) =>
            PhysicalPersonEuropeanDrivingLicenseService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonEuropeanDrivingLicenseService.delete(item.id),
        },
        PostingCertificate: {
          typeName: "Posting certificate",
          labelColor: "primary",
          attachmentField: "doc",
          name: (item) =>(!this.buttonDisabled(item.is_authorized) ? item.country + " " + item.data.certificate_kind: "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-a-module",
          downLoadFunc: (item) => PhysicalPersonA1ModuleService.getOne(item.id),
          delFunc: (item) => PhysicalPersonA1ModuleService.delete(item.id),
        },
        ResidencePermit: {
          typeName: "Residence Permit",
          labelColor: "info",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-permit-modal",
          updateFunc: (item, itemId) =>
            PhysicalPersonResidencePermitService.update(item, itemId),
          downLoadFunc: (item) =>
            PhysicalPersonResidencePermitService.getOne(item.id),
          delFunc: (item) => PhysicalPersonResidencePermitService.delete(item),
        },
        CertificateOfSuitabilityForWork: {
          typeName: "Certificate Suitability Of Work",
          labelColor: "success",
          attachmentField: "doc",
          name: (item) => (!this.buttonDisabled(item.is_authorized) ? item.label : "xxxxxx"),
          isEditable: true,
          isDeletable: true,
          modal: "create-suitability-work",
          updateFunc: (item, itemId) =>
            PhysicalPersonSuitabilityWorkService.update(item, itemId),
          downLoadFunc: (item) =>
            PhysicalPersonSuitabilityWorkService.getOne(item.id),
          delFunc: (item) =>
            PhysicalPersonSuitabilityWorkService.delete(item.id),
        },
        PayCheck: {
          typeName: "Paycheck",
          labelColor: "success",
          name: (item) => item.label,
          isEditable: true,
          isDeletable: false,
          modal: "create-paycheck-modal",
          downLoadFunc: function (item) {
            item.attachments.forEach((element) => {
              window.open(element.attachment, "_blank");
            });
          },
        },
        extracompanyvat_set: {
          typeName: "VAT",
          labelColor: "warning",
          name: (item) => item.vat | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-vat-modal",
          downLoadFunc: (item) => CompanyVatService.getOne(item.id),
          delFunc: (item) => CompanyVatService.delete(item.id),
        },
        companyregistrationcertificate_set: {
          typeName: "National CCIAA certificate",
          labelColor: "danger",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.registration_number : "xxxxxx") |
            "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-registration-certificate-modal",
          downLoadFunc: (item) =>
            CompanyRegistrationCertificateService.getOne(item.id),
          delFunc: (item) =>
            CompanyRegistrationCertificateService.delete(item.id),
        },
        insurancecertificate_set: {
          typeName: "Insurance - Third Party",
          labelColor: "primary",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.number : "xxxxxx") | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-ins-third-party-modal",
          downLoadFunc: (item) =>
            CompanyInsuranceCertificateService.getOne(item.id),
          delFunc: (item) => CompanyInsuranceCertificateService.delete(item.id),
        },
        social_security_certificates: {
          typeName: "Social Security Office Registration",
          labelColor: "success",
          name: (item) =>
            (!this.buttonDisabled(item.is_authorized) ? item.number : "xxxxxx") | "broken",
          isEditable: true,
          isDeletable: true,
          modal: "create-social-security-modal",
          downLoadFunc: (item) => CompanySocialSecurityService.getOne(item.id),
          delFunc: (item) => CompanySocialSecurityService.delete(item.id),
        },
      };
    },
  },

  watch: {
    "editModal.form.person_id": {
      async handler(val) {
        if (val) {
          await PhysicalPersonService.getOne(val).then(res => {
            return PhysicalPersonService.updateDate(res.data)
          }).then(res => {
            this.editingPerson = res;
          }).catch(err => {
            // Nel caso in cui non sia autorizzato l'errore è comunque 404
            if (err.response.status === 404) {
              this.$router.push({ name: '404' }); //unauthorized
              return
            }
            backendErrorSwal(err?.response?.detail);
          });
        }
      },
    },
    "editModal.validationErrors": {
      deep: true,
      handler(val) {
        const formObs = this.$refs.validationObserver;
        const newErrors = {};
        for (const key in this.editModal.item) {
          newErrors[key] = [...(val[key] ?? [])];
        }
        formObs.setErrors({ ...newErrors });
      },
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Employees Documents") },
    ]);
    this.isLoading = true;
    const [langLevels, educationKinds] = await Promise.all([
      PhysicalPersonLanguageService.getLevels(),
      PhysicalPersonEducationService.getChoices(),
    ]);
    this.languageLevels = langLevels;
    this.educationKinds = educationKinds;

    // await this.loadDocuments();
    await this.loadResidencePermitKinds();
    await this.loadCertificateKinds();

    this.isLoading = false;
  },

  methods: {
    buttonDisabled(is_authorized) {
      return !is_authorized || this.isAfterSale;
    },
    //se c'è un documento, se non hai i permessi ti dirà che non c'e autorizzazione
    titleDownloadButton(is_authorized) {
      return this.buttonDisabled(is_authorized) ? this.$t("You do not have permission to download attachments") : this.$t("Download attachments")
    },
    //Se non c'è nessun documento valuta se hai i permessi, se non hai i permessi restituisce non autorizzato, se ce l'hai ti dirà nessun contenuto
    titleNotPermissionAndNotContentButton(is_authorized) {
      return this.buttonDisabled(is_authorized) ? this.$t("You do not have permission to download attachments") : this.$t("No attachments found")
    },
    itemProvider(ctx, callback) {
      let fields = "";
      if (this.documentType) {
        fields += this.documentType;
      }
      SecondmentService.getDocuments({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "persona_name",
        search: ctx.filter,
        sortDesc: ctx.sortDesc,
        fields: fields,
        id: this.secondment.id,
        persona_id: this.userSelected,
        expiring: this.expiring,
      })
        .then((res) => {
          let out = res.results;
          if (this.users.length === 0) {
            this.users = res.persona_objs;
          }
          console.log(this.users)
          if (this.documentOptions.length === 0) {
            this.documentOptions = res.document_types.map((key) => {
              return {
                value: key,
                text: this.docTypes[key].typeName,
              };
            });
          }
          try {
            out.map((item) => {
              if (!this.docTypes[item.document_type]) {
                console.error(
                  `docTypes does not have a property ${item.document_type}`
                );
              } else if (
                typeof this.docTypes[item.document_type].name !== "function"
              ) {
                console.error(
                  `docTypes[${item.document_type}] does not have a method named 'name'`
                );
              } else {
                const name = this.docTypes[item.document_type].name(item);
                if (typeof name !== "string") {
                  console.error(
                    `docTypes[${item.document_type}].name does not return a string value`
                  );
                }
              }
            });
          } catch (error) {
            console.error(error);
          }
          this.total = res.count;
          callback(out);
        })
        .catch((err) => {
          console.error(err)
          callback([]);
        });
    },
    async loadResidencePermitKinds() {
      try {
        const {
          actions: {
            POST: {
              permit_kind: { choices },
            },
          },
        } = await PhysicalPersonResidencePermitService.getOptions();

        this.residencePermitOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },
    async loadCertificateKinds() {
      try {
        const {
          actions: {
            POST: {
              certificate_kind: { choices },
            },
          },
        } = await PhysicalPersonSuitabilityWorkService.getOptions();

        this.certificateOptions = choices;
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    isExpired(item) {
      if (item.is_valid) {
        return 'Valid'
      }
      else if (item?.expiring_in_days == null) {
        return 'Valid'
      }
      else if (item?.expiring_in_days < 0) {
        return 'Expired'
      }
      else if (item?.expiring_in_days < 90) {
        return 'Expiring'
      }
      else if (item?.expiring_in_days >= 90) {
        return 'Valid'
      }
      else {
        return 'Unknown'
      }
    },

    async download(item) {
      if (item.document_type === "PayCheck") {
        await this.downloadZip(item.id);
      } else {
        await this.downloadItem(item);
      }
    },
    async downloadItem(item) {
      const downLoadFunc = this.docTypes[item.document_type].downLoadFunc;
      try {
        const res = await downLoadFunc(item);

        window.open(res.attachment || res.doc, "_blank");
      } catch (err) {
        let message = "";
        if (err?.response?.status === 400) {
          message = err.response.data;
        } else {
          message =
            err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal(message, err);
      }
    },
    async downloadZip(id) {
      await EmployeeService.donwloadPaycheckAttachmentZip(id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const contentDisposition = response.headers["content-disposition"];
          let fileName = "unknown";
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename=(.+)/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          link.setAttribute("download", fileName);
          link.click();
          link.remove();
        })
        .catch((err) => {
          backendErrorSwal(
            err?.response?.data?.detail ??
            this.$t(
              "Unable to download the paycheck zip, please try again later"
            )
          );
          console.log(err);
        });
    },


    onFormInput(f) {
      this.editModal.form = f;
    },

    openEditModal() {
      this.$refs.editModal.show();
    },

    closeEditModal() {
      this.$refs.editModal.hide();
    },

    resetEditModal() {
      this.editModal.form = null;
      this.editModal.isSaving = false;
      this.editModal.validationErrors = null;
    },

    onEditModalHidden() {
      this.resetEditModal();
    },
    edit(item) {
      this.$router.push({
        name: "detail-physical-documents",
        params: { userID: item.persona_id },
      });
    },

    async updateDocument() {
      try {
        const updateFunc = this.docTypes[this.editModal.form.document_type].form.updateFunc;
        this.editModal.isSaving = true;
        await updateFunc(this.editModal.form, this.editModal.form.id);
        successToast(this.$t("Document Updated"));
        this.closeEditModal();
        this.loadDocuments();
      } catch (err) {
        if (err?.response?.status === 400) {
          this.editModal.validationErrors = err.response.data;
        } else {
          backendErrorSwal(err?.response?.data?.detail ?? "Failed to update the document. Please try again.");
        }
      } finally {
        this.editModal.isSaving = false;
      }
    },

  },
};
</script>
