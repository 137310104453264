var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Employees Documents")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('euro-checkbox', {
    staticClass: "mr-6 mt-5",
    attrs: {
      "text": _vm.$t('Expiring'),
      "size": "lg",
      "is-switch": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.expiring,
      callback: function callback($$v) {
        _vm.expiring = $$v;
      },
      expression: "expiring"
    }
  }), _c('euro-select', {
    attrs: {
      "class-name": "mb-0 min-w-250px mr-4",
      "placeholder": "Select employee",
      "options": _vm.users
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.userSelected,
      callback: function callback($$v) {
        _vm.userSelected = $$v;
      },
      expression: "userSelected"
    }
  }), _c('euro-select', {
    attrs: {
      "class-name": "mb-0 min-w-250px",
      "placeholder": "Select type",
      "options": _vm.documentOptions
    },
    on: {
      "input": function input($event) {
        return _vm.$refs.table.refresh();
      }
    },
    model: {
      value: _vm.documentType,
      callback: function callback($$v) {
        _vm.documentType = $$v;
      },
      expression: "documentType"
    }
  })], 1)]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('b-container', {
    staticClass: "px-0"
  }, [_c('b-row', [_c('b-col', [_c('datatable', {
    ref: "table",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.persona_name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('button', {
          staticClass: "label label-inline label-light-primary",
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-user-edit mr-2"
        }), _vm._v(_vm._s(item.persona_name))])];
      }
    }, {
      key: "cell.document_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          class: "font-weight-bold label label-inline label-light-".concat(_vm.docTypes[item.document_type].labelColor),
          attrs: {
            "id": 'a1-' + item.id
          }
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].typeName) + " ")])];
      }
    }, {
      key: "cell.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder h-100"
        }, [_vm._v(" " + _vm._s(_vm.docTypes[item.document_type].name(item)) + " ")]), item.document_type == 'PayCheck' ? _c('div', {
          staticClass: "text-muted text-size-sm"
        }, [_vm._v(" " + _vm._s(_vm.$t("Period")) + ": " + _vm._s(_vm.DateService.format(item.date, "YYYY-MM")) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell.status",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.document_type != 'PayCheck' && item.document_type != 'FiscalID' ? _c('div', [_c('div', [_vm.isExpired(item) === 'Valid' ? _c('div', [_c('span', {
          staticClass: "label label-success label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-success"
        }, [_vm._v(_vm._s(_vm.$t("Valid")))])]) : _vm.isExpired(item) === 'Expiring' ? _c('div', [_c('span', {
          staticClass: "label label-warning label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-warning"
        }, [_vm._v(_vm._s(_vm.$t('Expiring')))])]) : _c('div', [_c('span', {
          staticClass: "label label-danger label-dot mr-2"
        }), _c('span', {
          staticClass: "font-weight-bold text-danger"
        }, [_vm._v(_vm._s(_vm.$t("Expired")) + " ")])])])]) : _vm._e()];
      }
    }, {
      key: "cell.valid_to",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.valid_to ? _vm.DateService.format(item.valid_to, _vm.dateFormat) : "") + " ")])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-sm",
          class: {
            'btn-hover-primary': item.is_authorized && item.attachment
          },
          attrs: {
            "title": item.attachment ? _vm.titleDownloadButton(item.is_authorized) : _vm.titleNotPermissionAndNotContentButton(item.is_authorized),
            "disabled": !item.is_authorized || !item.attachment || _vm.isAfterSale
          },
          on: {
            "click": function click($event) {
              item.is_authorized && item.attachment ? _vm.download(item) : null;
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md",
          class: {
            'svg-icon-primary': item.is_authorized && item.attachment
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/Clip.svg"
          }
        })], 1)]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm ml-2",
          attrs: {
            "title": _vm.$t('View Details')
          },
          on: {
            "click": function click($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/General/User.svg"
          }
        })], 1)])], 1)];
      }
    }], null, true)
  }), _c('validation-observer', {
    ref: "validationObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var handleSubmit = _ref7.handleSubmit;
        return [_c('b-modal', {
          ref: "editModal",
          attrs: {
            "title": _vm.$t('Edit Form'),
            "size": "xl",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onEditModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h3', [_vm._v("Edit " + _vm._s(_vm.docTypes[_vm.editModal.form.document_type].typeName))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                class: {
                  'spinner spinner-light spinner-right': _vm.editModal.isSaving
                },
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.updateDocument);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeEditModal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_vm.editModal.form && _vm.docTypes[_vm.editModal.form.document_type].isEditable ? _c(_vm.docTypes[_vm.editModal.form.document_type].form.component, _vm._g(_vm._b({
          tag: "component"
        }, 'component', _vm.docTypes[_vm.editModal.form.document_type].form.props, false), _vm.docTypes[_vm.editModal.form.document_type].form.events)) : _c('p', [_vm._v(_vm._s(_vm.$t("Edit action not available for this document.")))])], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }